<template>
    <div class="sub-content part">
        <div class="heading">{{ content.title }}</div>
        <span v-if="description!==null" v-html="description"></span>
        <div class="splide-carousel slider" @click="showLightbox(0)">
            <splide :options="sliderOptions" ref="teaserCarousel" class="full-width" v-if="sliderImages!=null && sliderImages.length>0">
                <splide-slide class=" slide full-width" v-for="(slide,index) in sliderImages" :key="index" :aria-label="slide.description">
                    <div tabindex="-1" class="full-width profile-image-placeholder test2" :class="slide.class" :style="getBackgroundImage(slide.image)"></div> 
                    <span class="copyright" v-if="slide.copyright!=null">&copy; {{slide.copyright}}</span>
                </splide-slide>
            </splide>
        </div>
        <vue-easy-lightbox :visible="lightBoxVisible" :imgs="imgs" :index="lightBoxIndex" @hide="hideLightbox"></vue-easy-lightbox>
    </div>
</template>

<script>
    import { getFieldValues, showLoader, hideLoader, json_decode, getPlaceholderImage } from '@/utils/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';

    export default {
        name: "SubContent",  
        components: {
            Splide,
            SplideSlide,
            VueEasyLightbox: () => import ('vue-easy-lightbox')
        },
        props: {
          content: {
            type: Object,
            required: true,
        }, 
    },
    data(){
        return{
            sliderOptions: {
                type: 'loop',
                perPage: 3,
                perMove: 1,
            },
            lightBoxIndex: 0,
            lightBoxVisible: false,
        }
    },
    computed: {
        description(){
            return getFieldValues(this.content,'body');
        },
        sliderImages(){
            var teaserImgs = [];
            var images = getFieldValues(this.content, 'bilder');
            if(images !== null && Array.isArray(images)){
             for(var i = 0; i < images.length; i++){
                var image = json_decode(images[i]);
                if(typeof image === "object"){
                    teaserImgs.push({image:this.$backendUrl+image.path,class:'teaser-img', copyright:image.copyright,description:image.description});
                }
                else {
                  teaserImgs.push({image:this.$backendUrl+image,class:'teaser-img', copyright:null,description:null});  
              }
          }
      }
      else if(typeof images === 'string' || images instanceof String){
        var img = json_decode(images);
        teaserImgs.push({image:this.$backendUrl+img.path,class:'teaser-img', copyright:img.copyright,description:img.description});
    }
    return teaserImgs;
},
imgs(){
    if(this.sliderImages.length > 0){
        var lightBoxImages = [];
        for(var i= 0; i<this.sliderImages.length; i++){
            lightBoxImages.push({src: this.sliderImages[i].image,alt:this.sliderImages[i].description})
        }
        return lightBoxImages;
    }
    return null;
},
},
methods: {
    getBackgroundImage(image){
        return 'background-image: url("' + image + '")';
    },
    showLightbox(index){
        this.lightBoxVisible = true;
        this.lightBoxIndex = index;
    },
    hideLightbox(){
        this.lightBoxVisible = false;
    },
},
}
</script>

<style lang="scss" scoped>

    .heading{
        margin: 10px 0px 5px;
        font-size: 14px;
        font-weight: 700;
        font-family: 'elliot-bold', sans-serif;
    }

    .part{
        margin-bottom: 20px;
    }

    .profile-image {
        position: relative;
        z-index: 1;


        &-placeholder {
            height: 116px;
            background-repeat: no-repeat;  
            background-position: center center; 
            background-size: cover;
            &.logo{
                background-size: contain!important;
            }
        }
    }

</style>

<style lang="scss">
    .sub-content .splide-carousel .slide{
        padding: 10px;

    }
</style>
